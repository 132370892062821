@import "../../../../theme/color.scss";
@import "../../../../theme/variables.scss";

.Device_Estimated_Value_Box {
  .loaderSection {
    display: flex;
  }

  .currency-price {
    @include font-size24();
  }

  .loadingContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    @media only screen and (max-width: 800px) {
      top: 55%;
    }

    .loading-text {
      font-style: normal;
      font-weight: 500;
      @include font-size20();
      line-height: 33px;
      color: $new_primary_blue;

      @media only screen and (min-width: 1800px) {
        @include font-size28();
      }
    }
  }

  .resultSection {
    .loaderFrame {
      width: 300px;
      height: 380px;
      background-image: url("../../../../assests/Images/Border.png");
      background-size: cover;
      position: relative;

      @media only screen and (min-width: 1800px) {
        width: 428px;
        height: 550px;
      }

      .result-content {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 55%;
        transform: translate(10%, -50%);
        width: 80%;

        .result-view {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: flex-start;
          width: 100%;

          @media only screen and (max-width: 800px) {
            flex-direction: column;
          }

          .result-icon {
            display: flex;
            width: 20%;
            justify-content: center;
          }

          .info {
            display: flex;
            width: 70%;

            @media only screen and (max-width: 800px) {
              width: 95%;
            }

            .result-condition {
              color: $new_primary_blue;
            }

            .result-condition-price {
              color: $new_primary_blue;
              font-weight: bold;
              @media only screen and (max-width: 800px) {
                text-wrap: nowrap;
              }
            }
          }
        }

        .result-buttons {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
      .result-content-arabic{
        transform: translate(-10%, -50%);
      }
    }
  }

  .spinner {
    width: 100px;
    /* Adjust width of spinner image as needed */
    height: auto;
    /* Maintain aspect ratio */
  }

  /* Define the keyframes for the spin animation */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* Apply the spin animation to an element */
  .spin {
    /* Use the defined keyframes */
    animation: spin 2s linear infinite;
    /* Adjust duration and timing function as needed */
  }

  .result-divider {
    background: $new_primary_blue !important;
    height: 2px;
  }
}