@import "../../theme/color.scss";
@import "../../theme/variables.scss";

.notification {
  display: flex;
  border: 2px solid;
  border-image: linear-gradient(to right, #55b4df, #71e4ac);
  border-image-slice: 1;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  margin-right: 10px;

  @media only screen and (max-width: 900px) {
    border: 1px solid;
  }

  .icon-container {
    margin-right: 10px;
  }

  .icon-bell {
    width: 30px;
    height: 30px;
  }

  .details-container {
    flex: 1;
  }

  .details {
    margin: 0;
    color: #041c3f;
    @include font-size16();
  }

  .date {
    margin: 0;
    font-size: 0.8em;
    color: #595959;
  }
  .showMore {
    @include font-size15();
    color: #55b4df;
  }
}
.unread {
  background-color: #cde0fd59;
}

.read {
  background-color: #ffffff;
}
