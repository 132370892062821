@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.PiceaFlow {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: max(5px, 1vmax);
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
    height: 250px;
    justify-content: flex-end;
    gap: 10%;
    box-shadow: $box-shadow;
    border: 1px solid white;
    border-radius: 20px;
    transform-origin: unset; //skeleton
    transform: unset; //skeleton
    padding: 5% 0 2%;
    text-transform: capitalize;
    font-weight: 400;
    color: $primary-color-opacity;
    position: relative;
    background-color: white;

    &:hover {
      box-shadow: 0px 0px 5px 1px $primary-color-opacity;
    }
    img {
      object-fit: contain;
      max-width: 70%;
      max-height: 50%;
    }
    p {
      color: black;
      @include font-size20();
      text-align: center;
    }
  }

  .deviceInfo {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .blueColor {
    color: #4ba4f0;
  }
}
.titleDetails {
  color: #727272;
  text-align: left !important;
}
.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.d-flex {
  display: flex !important;
  justify-content: space-between;
}

.flex-direction-row {
  flex-direction: row;
}
.justify-content-start {
  justify-content: start;
}
.w-70 {
  width: 70%;
}
.dflex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}
.f24 {
  font-size: 24px;
}
.f20 {
  font-size: 20px;
}
.Buttons {
  display: flex;
  justify-content: space-between;
  @include sm {
    flex-direction: column;
    gap: 10px;
    a {
      width: 100%;
    }
  }
  .back {
    margin-right: auto;
  }
  .buttonQuestions {
    min-width: 100px;
  }
}
.button-val {
  text-transform: capitalize;
  cursor: pointer;
  margin: 5px;
  padding: 10px;
  border-radius: 0.3rem !important;
  text-align: center;
  color: #666;
  border: 1px solid #666;
  min-width: 70px;
  height: fit-content;
  @include font-size16;
  &:hover {
    background-color: #f8f8f8;
    border-color: #d6d6d6;
  }
}
