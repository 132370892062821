@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.open-transaction {
  transition: 0.1s all ease-out;
margin-bottom: 10px;
  .trBody {
    cursor: pointer;
    transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      background-color: $primary-color-opacity;
    }
  }

  .tablecontainerheight {
    min-height: 500px;
    @media only screen and (min-width: 1800px) {
      min-height: 600px;
    }
  }

  .open-transaction-body {
    width: 100%;
    min-height: 100%;
  }

  .barcode {
    flex-grow: 2;

    @media only screen and (max-width: 900px) {
      flex-grow: 1;
    }
  }

  .mainTitle {
    @include font-size40();
    color: #fff;
  }
}

.filterComponent {
  .MuiPaper-root {
    min-width: 200px !important;
  }

  .MuiFormLabel-root {
    @include font-size20;
  }

  .radioFilter {
    .MuiFormControlLabel-label {
      font-size: 14px;
      // white-space: nowrap;
    }
  }
}

.noData {
  text-align: center;
  color: gray;
  padding: 5%;
  font-size: 30px;
  text-transform: capitalize;
  font-weight: 500;
}

.toggle-filter {
  display: flex;
  justify-content: flex-end;
  gap: 1%;

  .filter-icon {
    padding: 4px 10px !important;
    border-radius: 4px !important;
    background-color: $primary-color !important;
  }
}

.transaction-filter {
  justify-content: end;
  display: flex;
  padding: 5px;
  border: 1px solid transparent;
  flex-direction: column-reverse;


  @include md {
    margin-top: 10px;
    flex-flow: column-reverse;
    gap: 10px;
    background-color: white;
    padding: 20px;
    box-shadow: $box-shadow;
    border-radius: 5px;
    align-items: center;
  }

  .filter-elements {
    display: flex;
    flex-flow: wrap;
    align-items: flex-end;
    gap: 10px;
    flex: 1;

    @include md {
      flex-flow: column nowrap;
      align-items: flex-start;
    }
  }

  .filter-actions {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 5% 0%;
    width: fit-content;
    margin-bottom: 10px;
    justify-content: end;
    width: 100%;

    @include md {
      gap: 2%;
      flex-flow: row-reverse;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }

    .side-filter {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      white-space: nowrap;

      .reset-filter {
        @include font-size15();
        margin: 0px 5px;
      }

      .reset-icon {
        padding: 10px !important;
        border-radius: 4px !important;
        color: #4ba4f0 !important;
        font-size: 10px;
      }
    }
  }
}

.footerHeight {
  @media only screen and (min-width: 1000px) and (max-width: 1799px) {
    height: 50px;
  }

  @media only screen and (min-width: 1800px) {
    height: 80px;
  }
}