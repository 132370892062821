// Breakpoint prefix	Minimum width	CSS
$sm-screen: 640px;
$md-screen: 768px;
$lg-screen: 1024px;
$xl-screen: 1280px;
$xxl-screen: 1536px;
$border-radius: 5px;
$box-shadow: 0px 0px 4px rgba(222, 228, 237, 0.8);
:export {
  mdScreen: $md-screen;
}
// Small devices
@mixin sm {
  @media (max-width: #{$sm-screen}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$md-screen}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$lg-screen}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: #{$xl-screen}) {
    @content;
  }
}

// Extra Extra large devices
@mixin xxl {
  @media (max-width: #{$xxl-screen}) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (max-width: ($screen + "px")) {
    @content;
  }
}

@mixin font-size45() {
  font-size: clamp(25px, 2.343vmax, 45px);
}

@mixin font-size40() {
  font-size: clamp(20px, 2.343vmax, 40px);
}

@mixin font-size() {
  font-size: clamp(16px, 1.45vmax, 28px);
}
@mixin font-size28() {
  font-size: clamp(16px, 1.3vmax, 28px);
}

@mixin font-size26() {
  font-size: clamp(16px, 1.3vmax, 26px);
}

@mixin font-size25() {
  font-size: clamp(16px, 1.25vmax, 25px);
}

@mixin font-size24() {
  font-size: clamp(16px, 1.25vmax, 24px);
}

@mixin font-size22() {
  font-size: clamp(16px, 1.15vmax, 22px);
}

@mixin font-size20() {
  font-size: clamp(16px, 1.05vmax, 20px);
}

@mixin font-size18() {
  font-size: clamp(14px, 0.9375vmax, 18px);
}

@mixin font-size17() {
  font-size: clamp(17px, 0.9375vmax, 14px);
}

@mixin font-size15() {
  font-size: clamp(12px, 0.8375vmax, 15px);
}

@mixin font-size16() {
  font-size: clamp(12px, 0.8375vmax, 16px);
}

@mixin font-size14() {
  font-size: clamp(10px, 0.73vmax, 14px);
}
