@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.buttonComponent {
  display: flex;
  min-width: 100px !important;
  min-height: 20px;
  height: fit-content;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.75;
  letter-spacing: 0.02857em;

  cursor: pointer;
  // @include sm {  //   width: 90% !important;
  //   margin: 5% auto 0;
  //   height: 35px;
  // }
  .cardSVG {
    path {
      fill: $primary-color;
    }
  }
  p,
  a {
    margin: 0;
    width: 100%;
    text-align: center;
    $secondry-color-space: nowrap;
    pointer-events: none;
    @include font-size16();
    // min-height: 25px;
  }
}
.none {
  display: none;
}
.buttonsContainer {
  display: flex;
  gap: 5%;
  justify-content: flex-end;
  margin-top: 1%;
}
.selectedColor {
  color: white !important;
  p {
    color: white !important;
  }
}
.isntSelectedColor {
  color: $primary-color !important;
}

.selectedButton {
  background-color: #4ba4f0;
  border-radius: 50px !important;
  padding: 6px 16px !important;
  text-align: center;
  font-size: 15px;
  &:hover {
    background-color: rgb(52, 114, 168) !important;
    color: white !important;
  }
}
.isntSelectedButton {
  color: #4ba4f0;
  // border: 1px solid rgba(75, 164, 240, 0.5) !important;
  border-radius: 50px !important;
  padding: 6px 16px !important;
  &:hover {
    color: #4ba4f0;
  }
}
