@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.disclaimerText {
  // page-break-after: always;
  padding-left: 5%;
}

.disclaimer {
  margin: 0% auto;

  hr {
    width: 100%;
  }

  .watermark {
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 0;
    bottom: 0;
    margin: auto;
    left: -25px;
    z-index: 0;

    @media screen {
      height: 0;
      overflow: hidden;
      min-height: 0;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    width: 95vw;
  }

  header,
  .header-space,
  footer,
  .footer-space {
    padding-left: 5%;
    height: 55px;
  }

  .titlespace {
    padding-left: 5%;
    height: 50px;
  }


  header {
    position: fixed;
    top: 0;
    width: 100%;

    @media screen {
      height: 0;
      overflow: hidden;
      min-height: 0;
    }

    .LogoWithTextSVG {
      @media screen {
        display: none;
      }

      position: absolute;
      top: 0;
      right: 0;
    }

    .companyLogo {
      @media screen {
        display: none;
      }

      position: absolute;
      top: 0;
      right: 2;
      left: 0;
    }
  }

  footer {
    width: 100%;
    position: fixed;
    bottom: -25px;
    text-align: center;
    margin: auto;

    @media screen {
      height: 0;
      overflow: hidden;
      min-height: 0;
    }

    .LogoWithTextSVG {
      @media screen {
        display: none;
      }

      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

li::marker {
  color: $primary-color;
}

.disclaimer {
  display: block;

  @media screen {
    max-height: 0;
    overflow: hidden;
  }
}

.printHide {
  @media print {
    display: none !important;
  }
}

@media print {
  .printable-content {
    page-break-before: always;
  }
}

.comp_logo_disc {
  min-width: 50px !important;
  max-width: 60px !important;
}