@import "/src/theme/variables.scss";
@import "/src/theme/color.scss";

.headerr {
  width: 100%;
  max-width: 100%;
  height: 55px;
  -webkit-box-shadow: 0px 3px 7px 0px rgba(75, 164, 240, 0.3);
  box-shadow: 0px 3px 7px 0px rgba(75, 164, 240, 0.3);
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  background-color: #071d40;
  transition: 0.1s all ease-out;
  z-index: 1000;
  top: 0;
  position: fixed !important;
  padding: 0 2%;

  @media (max-width: 480px) {
    height: 40px;
  }
  @media (max-width: 700px) {
    height: 50px;
    padding: 0 5px;
  }

  .svg {
    width: 20px;
    color: #fff;
    margin-left: 10px;
  }

  .Mui-selected {
    .svg {
      path {
        fill: $primary-color;
      }
    }
  }

  .logo {
    padding-right: 10px;
  }

  .name {
    color: #fff;
    cursor: pointer;
    @include font-size16();
    text-transform: none;
  }

  .langCode {
    margin: 0px 11px 0px 0px;
    color: white;
    font-size: 16px;
  }

  .secondcompany-logo {
    height: 100%;
    max-width: 100%;
    width: 50%;
    justify-content: center;
    align-items: center;
    display: flex;

    .company_logo {
      max-height: 100%;
    }
  }

  .third-section {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    .showItems {
      @media (max-width: 700px) {
        display: none;
      }
    }

    .username {
      text-align: center;
      text-wrap: nowrap;
    }
  }

  .item,
  .item-right {
    padding: 15px 10px;

    @media (max-width: 480px) {
      padding: 8px;
    }
  }

  .noitempad{
    padding: 0px !important;
  }

  .item + .item {
    border-left: 1px solid #ffffff73 !important;
    border-radius: 0;
  }

  .item-right + .item-right {
    border-right: 1px solid #ffffff73 !important;
    border-radius: 0;
  }
}

.logoutPopover {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: $box-shadow;
  border-radius: 10px;
  width: 200px;
  z-index: 100;

  div {
    display: flex;
    align-items: center;
    gap: 10%;
    padding: 3% 5%;

    &:nth-child(1) {
      border-bottom: 1px solid rgba(168, 168, 168, 1);
    }
  }

  a {
    color: black;
    text-decoration: none;

    p {
      margin: auto 0;
    }
  }

  .iconFlag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
}

.languagePopover {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: $box-shadow;
  border-radius: 10px;
  width: 200px;
  z-index: 100;

  div {
    display: flex;
    align-items: center;
    gap: 5%;
    padding: 2% 5%;

    &:nth-child(1) {
      border-bottom: 1px solid rgba(168, 168, 168, 1);
    }
  }

  a {
    color: black;
    text-decoration: none;

    p {
      margin: auto 0;
    }
  }
}

.showBell {
  display: none !important;

  @media (max-width: 700px) {
    display: flex !important;
  }
}

.mleft {
  margin-left: 10%;
  @media (max-width: 800px) {
    margin-left: 0%;
  }
}

.mrRight {
  margin-right: 10%;
  @media (max-width: 800px) {
    margin-right: 0%;
  }
}

.notificationPopover {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: $box-shadow;
  border-radius: 10px;
  width: 500px;
  z-index: 100;
  padding: 10px;

  @media (min-width: 1800px) {
    width: 600px;
  }
}
