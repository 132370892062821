/* Default styles for the main container */
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

}

/* Ensure main body fills remaining vertical space */
.main-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Common styles for pages */
.main-sub-body {
  flex: 1;
  width: 90%;
  margin: 0 auto;
  min-height: calc(100vh - 80px);
  max-width: 2000px;
  padding-top: 80px;
  @media only screen and (max-width: 800px) {
    min-height: calc(160vh - 80px);
    //max-height: calc(180vh - 80px);
    // overflow: auto;
  }
}

.main-login-body {
  flex: 1;
  width: 90%;
  margin: 0 auto;
  min-height: calc(100vh - 80px);
  max-width: 2000px;
  display: flex;
  justify-content: center;
}

/* Styles for pages with solid blue background */
.blueBackground {
  background-color: #f8faff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 75vh;
}

/* Styles for pages with gradient background */
.gradientBackground {
  background: linear-gradient(
      180deg,
      rgba(1, 29, 68, 0.65) 42.47%,
      rgba(45, 126, 239, 0.65) 100%
    ),
    #5795ec;
}