@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";
.modal-body {
  @media only screen and (max-width: 1000px) {
    width: 100% !important;
  }
}
.modal-footer-btn {
  width: 100%;
  display: flex;
  justify-content: space-around;
  .btn {
    width: 150px;
    height: 50px;
    background-color: $primary-color;
    border-radius: 30px;
    border: none;
    border: 1px solid $primary-color;
    color: $white;
    font-size: 20px;
    font-family: Roboto;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 1050px) {
      width: 50%;
    }
    @media only screen and (max-width: 558px) {
      font-size: 20px;
    }
  }

  button:hover {
    background-color: $white;
    color: $primary-color;
  }
}

.modal-content {
  width: 70%;
  min-height: 10px;
  margin: auto;
  @media only screen and (max-width: 800px) {
    width: 80%;
  }
  @include sm {
    width: 90%;
  }
}
.dialogComp {
  overflow: hidden !important;
  .modalBody {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 30vw;
    max-width: inherit;
    padding: 5%;
    text-align: center;
    min-width: 300px;
    .children {
      font-size: 28px;
    }
  }
  .Buttons {
    display: flex;
    width: 100%;
    gap: 5%;
  }
  // width: 50vw;
}
