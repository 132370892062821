.employeeformContainer {
  .rowContainer {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 15px 0px;

    @media only screen and (min-width: 1400px) {
      gap: 20px 0px;
    }
  }

  @media only screen and (min-width: 1400px) {
    margin: 10% auto;
    justify-content: center;
    display: flex;
  }
}