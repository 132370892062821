@import "../../../theme/color.scss";
@import "../../../theme/variables.scss";

.header_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.model_name {
  @include font-size24();
  font-weight: 500;
  line-height: 28.13px;
  color: #fff;
  margin: 0px !important;
}

.main_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 75vh;
  max-height: 85vh;
  gap: 10px;

  @media only screen and (min-width: 901px) and (max-width: 1100px) {
    column-gap: 10%;
  }

  @media only screen and (min-width: 800px) and (max-width: 900px) {
    column-gap: 15%;
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }

  .step4 {
    display: flex;
    flex: 1;
    min-width: 0;
  }

  .Questions_box {
    display: flex;
    flex-direction: column;
    justify-content: start;
    background-color: $new_light_blue;
    border-radius: 10px;
    border: 1px solid $new_light_blue;
    flex: 3;
    min-width: 0;
    padding: 1%;

    @media only screen and (max-width: 1300px) {
      .side-form {
        flex: 1.5;
      }
    }

    @media only screen and (max-width: 800px) {

      .side-deviceInfo,
      .side-form {
        flex: none;
        width: 100%;
      }
    }

    .Questions_box_title {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $new_primary_blue;
      @include font-size26();
    }

    .Questions_box_stepper {
      //height: 100%;
      padding: 1%;

      .back_button_wrap {
        display: flex;
        align-items: center;

        &:hover {
          background-color: none;
        }

        .back_button {
          color: $new_primary_blue;
          margin: 0px 5px;
          @include font-size18();
        }
      }
    }

    .Question_answers_section {
      // padding: 3%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      min-height: 50vh;
      height: calc(100vh - 40vh);
      overflow-x: hidden;
      // overflow-y: scroll;

      @media only screen and (min-width: 1800px) {
        height: calc(100vh - 30vh);
        overflow-x: hidden;
        // overflow-y: scroll;
      }

      @media only screen and (max-width: 350px) {
        height: calc(100vh - 25vh);
        overflow-x: hidden;
      }

      .question_title {
        color: $dark_primary_blue;
        @include font-size24();
        font-weight: 500;
        padding: 1%;
      }

      .active_step {
        display: flex;
        justify-content: end;
        color: $dark_primary_blue;
        margin: 0;
        padding: 2%;
      }

      .Answers {
        display: flex;
        justify-content: start;
        flex-direction: row;
        gap: 15px;
        width: 100%;

        @media only screen and (min-width: 1800px) {
          //align-items: center;
        }

        @media only screen and (max-width: 800px) {
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
        }

        label {
          color: #000;
          font-weight: 600 !important;
          // /white-space: nowrap;
        }

        .Answers_section_questions {
          display: flex;
          flex-direction: column;
          width: 70%;

          @media only screen and (min-width: 1800px) {
            width: 65%;
          }

          @media only screen and (max-width: 800px) {
            width: 95%;
          }

          .answer_main {
            color: #000;
            font-weight: bold;
          }

          .answer_description {
            color: $description_gray;
            padding-left: 4%;
            width: 100%;

            @media only screen and (max-width: 1800px) {
              width: 90%;
            }
          }

          .answer_description_arabic {
            padding-right: 5% !important;
          }

          .span-answers {
            padding: 0% 3%;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
          }
        }

        .Answers_section {
          display: flex;
          flex-direction: column;
          width: 70%;

          @media only screen and (min-width: 1800px) {
            width: 65%;
          }

          @media only screen and (max-width: 800px) {
            width: 95%;
          }

          .answer_main {
            color: #000;
            font-weight: bold;
          }

          .answer_description {
            color: $description_gray;
            padding-left: 4%;
            width: 100%;

            @media only screen and (max-width: 1800px) {
              width: 90%;
            }
          }

          .answer_description_arabic {
            padding-right: 5% !important;
          }

          .span-answers {
            padding: 0% 3%;
            display: flex;
            align-items: start;
            flex-direction: row;
            justify-content: space-between;
          }
        }

        .Answers_image {
          display: flex;
          justify-content: center;
          height: 100%;
          width: 25%;

          @media only screen and (min-width: 1800px) {
            width: 30%;
          }

          @media only screen and (max-width: 800px) {
            width: 150px;
            max-height: 150px;
            height: auto;
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
    height: 2px;
    background: #71e4ac !important;
  }

  ::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg,
        #4ba4f0 0%,
        #55b4df 30.5%,
        #71e4ac 100%,
        #71e4ac 100%) !important;
    border-radius: 50px;
  }
}

.bold-label {
  font-weight: bold !important;
  font-size: 18px !important;
  /* Use !important to ensure the style is applied */
  @media only screen and (max-width: 400px) {
    font-size: 15px !important;
  }
}

.device_summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }

  .device_summary_questions {
    width: 50%;
    align-items: start;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px 0px;

    @media only screen and (min-width: 1800px) {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

    }

    @media only screen and (max-width: 800px) {
      width: 95%;
      gap: 10px 0px;
    }

    .sub-summary {
      width: 100%;
    }

    .device_summary_qa {
      color: $dark_primary_blue;
      @include font-size20();
      font-weight: 500;
      padding: 1% 3%;
    }

    .device_summary_an {
      @include font-size18();
      color: #000;
      font-weight: 600;
      padding-left: 6.5%;
    }

    .device_an_right {
      padding-right: 3%;
    }
  }

  .device_summary_estimation {
    display: flex;
    height: fit-content;
    width: 40%;

    @media only screen and (min-width: 1200px) {
      justify-content: center;
    }

    @media only screen and (max-width: 800px) {
      width: 95%;
      display: flex;
      justify-content: center;
    }
  }
}



.device_summary_title {
  color: $dark_primary_blue;
  @include font-size24();
  font-weight: 500;
  padding: 1%;
}

.divider {
  // background: linear-gradient(90deg,
  //     #4ba4f0 0%,
  //     #55b4df 30.5%,
  //     #71e4ac 100%,
  //     #71e4ac 100%);
  background: #55b4df;
  height: 2px !important;
  opacity: 0.8;
  width: 100%;


  @media only screen and (min-width: 1200px) {
    margin: 0.25rem 0;
  }

  @media only screen and (max-width: 800px) {
    margin: 0.25rem 0;
    width: 90%;
  }
}

.Answers_img {
  max-width: 90%;
  max-height: 100%;
  object-fit: contain;

  @media only screen and (min-width: 1800px) {
    width: 400px;
    height: 400px;
  }
}

.div-margin10 {
  margin: 10px;

  @media only screen and (max-width: 800px) {
    margin: 5px;
  }
}

.visibilityHidden {
  visibility: hidden;
}

.flipArrow {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.flipArrow-en {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.display-grid {
  display: grid !important;
  grid-template-columns: 200px auto;
  column-gap: 0px !important;
  row-gap: 0px !important;
}

.padding6 {
  padding: 0% 6% !important;
}

.paper_icon {
  @media only screen and (max-width: 500px) {
    width: 70% !important;
  }

  @media only screen and (min-width: 1200px) {
    width: 85% !important;
  }

  @media only screen and (min-width: 1800px) {
    width: 80% !important;
  }

}