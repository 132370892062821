@import "/src/theme/color.scss";

.form-control {
  border: none;
  border-bottom: 1px solid $dark-primary;
  border-radius: 0px;
  padding-left: 0px;
  // z-index: 1;
  &:focus {
    color: $black;
    background-color: $white;
    border-bottom: 1px solid $primary-color !important;
    outline: 0;
    box-shadow: none;
  }
  &::placeholder {
    margin-left: none !important;
    padding: 0px;
  }
}

.form-check-input:checked {
  background-color: $primary-color;
  border-color: $primary-color;
}

.OrLogin {
  color: $grey;
  display: flex;
  justify-content: center;
  text-decoration: none;

  &:before {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #c4c4c4;
    margin: auto;
    margin-right: 10px;
  }
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #c4c4c4;
    margin: auto;
    margin-left: 10px;
  }
  &:hover {
    color: $grey;
  }
}

.icon {
  border: 1px solid #0000001f;
  border-radius: 50%;
  padding: 1%;
  margin: 5px;
}
.input-group > .form-control {
  width: 100%;
  flex: none !important;
}

.input-group-text {
  background-color: transparent !important;
  border: none;
  border-radius: 0px !important;
  z-index: 50;
  position: absolute;
  right: 2%;
  padding: 0% !important;
  top: 0;
  bottom: 0;
  height: fit-content;
  margin: auto;
  &:hover {
    svg path {
      fill: $primary-color;
    }
  }
}

.form-select {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px;
  padding-left: 0px;
}

.form-control.is-invalid {
  background-image: none !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.form-control.is-invalid {
  background-image: none !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}
.absolute {
  margin: auto;
}

// accordion layout animation
.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
form {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
