$primary-color: #4ba4f0;
$primary-color-opacity: rgba(75, 164, 240, 0.3);
$dark-primary: #041c3f;
$light-gray: #a8a8a8;
$black: #041c3f;
$white: #ffffff;
$darkwhite: #e5e5e5;
$darkest-grey: #646464;
$dark-grey: #8c8c8c;
$grey: #c4c4c4;
$light-grey: #dcdcdc;
$border-grey: #c4c4c4;
$new_primary_blue: #234292;
$dark_primary_blue: #1B3370;
$description_gray:#243349;
$new_primary_dark_blue:#041C3F;
$new_light_blue:#EDF9FF;
:export {
  primaryColor: $primary-color;
}