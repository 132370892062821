.watermark {
  position: fixed;
  width: fit-content;
  height: fit-content;
  top: 0;
  bottom: 0;
  margin: auto;
  left: -25px;
  z-index: 0;
  @media screen {
    height: 0;
    overflow: hidden;
    min-height: 0;
  }
}
.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 300px;
}
