@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.Order_details {
  width: 100%;
  text-transform: capitalize;

  h4 {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    font-weight: 500;
    color: #fff;
  }

  .Items_summary {
    .headerAccor {
      @media only screen and (max-width: 900px) {
        display: none;
      }
    }

    .accordions {
      padding: calc(0px + 1%) calc(5px + 2%);

      span {
        padding: 10px 0;
      }

      .headerAccordion {
        @include font-size18();

        line-height: 28px;
        font-weight: 500;
        padding: 10px 0;
      }

      .is-open .list2 {
        font-weight: 500;
        border-bottom: 1px solid black;

        @media only screen and (max-width: 900px) {
          border-bottom: none;
        }
      }
    }

    .accordion {
      margin-bottom: 2%;
    }

    &::-webkit-scrollbar {
      width: 5px !important;
    }

    .Table_titles {
      padding: 0 3%;
      margin-bottom: 1%;
      display: flex;
      align-items: center;
      height: 50px;
      font-weight: 500;
      gap: 40%;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
      white-space: nowrap;

      &:hover {
        box-shadow: 0px 0px 5px 1px $primary-color-opacity;
      }

      p {
        width: 15%;
        margin: 0;
        font-weight: 500;
      }
    }
  }

  .Details_row {
    margin-top: 5%;
    display: flex;
    flex-wrap: wrap;
    gap: 10%;

    .Summary {
      margin-bottom: 5%;
      width: 45%;
      color: $new_primary_blue;

      p {
        margin: 0%;
        // text-align: end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include font-size16();
      }

      .Info {
        background-color: white;
        border-radius: 10px;
        padding: 2% 5%;
        border: 1px solid;

        .Sub_row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2%;
          min-height: 25px;
          font-size: 17px;

          .Left {
            font-weight: 500;
          }
        }

        .buttonContainer {
          display: flex;
          gap: 5%;
        }
      }
    }
  }

  svg {
    // width: 15px;
    align-self: center;
  }

  .sale_number,
  .date,
  .price {
    line-height: 28px;
    font-weight: 500;
    padding: 10px 0;
    color: $new_primary_blue;
  }

  .newOffer {
    color: green;
    font-size: 10px !important;
  }

  @media only screen and (max-width: 900px) {
    svg {
      //display: none;
    }
  }

  .newOffer {
    color: green;
    font-size: 10px !important;
  }

  .estimated_value,
  .shipping {
    font-style: normal;
    font-weight: 500;
    @include font-size20();
    line-height: 28px;
    display: flex;
    align-items: center;
    color: $grey;

    @media only screen and (max-width: 400px) {
      font-size: 14px !important;
    }
  }

  .offered_value {
    @include font-size18();
    line-height: 28px;
    display: flex;
    align-items: center;
    color: $black;
    color: $new_primary_blue;
    white-space: break-spaces;
  }

  .mobile_svg {
    display: none;

    @media only screen and (max-width: 900px) {
      display: flex;
      margin: auto auto 9px;
      width: 14px;
      height: 7px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .accordion_list_wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .list2 {
      direction: ltr;
    }
  }

  .Order_details {
    .Items_summary {
      .Table_titles {
        font-size: 15px;
        font-weight: 500;
      }
    }

    .Details_row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 50px;

      .Summary {
        width: 100%;

        .Info {
          font-size: 12px;
        }
      }
    }
  }
}

.accordion-opened {
  svg {
    transform: rotate(180deg);
  }
}

.hide svg {
  //display: none;
}

.accordions {
  display: flex;
  justify-content: space-between;
  margin: max(1%, 10px) auto 0;
  background: $white;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: calc(0px + 1%) calc(15px + 2%);
  color: $new_primary_blue;

  span {
    flex: 1 1;
  }

  .list1 {
    display: none;

    @media only screen and (max-width: 900px) {
      width: 30%;
      flex-direction: column;
      max-width: calc(100% - 80px);
    }
  }

  .list2 {
    width: 100%;

    @media only screen and (max-width: 900px) {
      flex-direction: column;
      align-items: flex-end;
      width: 70%;
      direction: rtl;
      text-align-last: right;
    }

    span {
      align-items: center;
    }
  }
}

.accordion_list {
  display: flex;
  justify-content: space-between;
  // width: 100%;
  white-space: nowrap;
  position: relative;
  color: $new_primary_blue;

  .element {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $new_primary_blue;
  }

  .svgAccordion {
    position: absolute;
    right: 20px;
    cursor: pointer;

    @media only screen and (max-width: 900px) {
      //display: none;
    }
  }

  span {
    white-space: nowrap;
    // text-align: left;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    color: $new_primary_blue;
    @include font-size18();
  }
}

.flex2 {
  flex-grow: 4 !important;
}

.flex3 {
  flex-grow: 8 !important;
}

.Collapsible {
  width: 100%;
}

.Collapsible__trigger {
  width: 100%;
}

svg {
  transition-duration: 500ms;
}

.accordions.table-header {
  @media only screen and (max-width: 900px) {
    display: none;
  }
}
