@import "/src/theme/variables.scss";
@import "/src/theme/color.scss";

.main-notification-container {
  background-color: white;
  border: 1px solid #041c3f;
  border-radius: 15px;
  padding: 1% 2%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 900px) {
    border-radius: 5px;
  }

  .title {
    font-weight: 600;
    @include font-size26();
    display: flex;
    align-items: center;
    text-align: center;
    color: #041c3f;
    padding: 10px 0%;
  }

  .notification-container {
    overflow-x: hidden;

    @media only screen and (min-width: 900px) {
      max-height: 65vh;
      height: auto;
    }

    @media only screen and (min-width: 1800px) {
     // min-height: 70vh;
     min-height: 10vh;
      max-height: 75vh;
    }
  }

  .notification-button {
    display: flex;
    justify-content: end;
    flex-direction: column;
    align-items: end;
    height: 50px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to right, #55b4df, #71e4ac);
    ;
  }
}

.border-none {
  border: 0px !important;
}