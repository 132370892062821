@import "/src/theme/variables.scss";
@import "/src/theme/color.scss";

.change_password {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .submit {
    position: relative;
    align-self: flex-end;
  }
}
