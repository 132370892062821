@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";
.PaymentMethod {
  margin: 20vh auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: max(25px, 2vmax);

  .boxes {
    display: flex;
    justify-content: center;
    gap: max(25px, 2vmax);

    .box {
      background-color: white;
      display: flex;
      flex-direction: column;
      border: 1px solid white;
      align-items: center;
      margin: auto 0;
      box-shadow: $box-shadow;
      border-radius: 20px;
      height: 225px;
      justify-content: flex-end;
      gap: 10%;
      padding: 5% 0 2%;
      text-transform: capitalize;
      font-weight: 400;
      &.selected {
        background-color: rgba(233, 245, 255, 0.8);
        border: 1px solid $primary-color-opacity;
      }
      &:hover {
        box-shadow: 0px 0px 5px 1px $primary-color-opacity;
      }
      p {
        color: black;
        font-size: 20px;
      }
      svg {
        max-width: 80%;
        max-height: 55%;
      }
    }
  }
  .continueButton {
    justify-self: flex-end;
    max-width: 200px;
  }
  .selectdialogDisclaimer {
    border-radius: 20px;
    border: 1px solid #0003;
  }
  .dialogAction.MuiDialogActions-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px 24px;
  }
}

.PrepaidPaperPrint {
  padding-left: 5%;
  height: 85vh;
  width: 100%;
  z-index: 1;
  .barcode {
    margin-top: 15px;
    float: right;
  }

  h4 {
    color: $primary-color;
  }
  .voucher {
    max-width: 800px;
    padding: 20px;
    box-shadow: $box-shadow;
    border-radius: 10px;
    background-color: white;
    i {
      color: $dark-grey;
      @include font-size16;
      b {
        color: $primary-color;
      }
    }
    p {
      margin: 0;
    }
    .voucherBody {
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
    }
    .backgroundSVG {
      position: absolute;
      top: 0;
      right: 0;
    }
    .GiftSVG {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 10%;
      width: 50px;
    }
    .LogoWithTextSVG {
      width: 20%;
      height: fit-content;
      margin-bottom: 20px;
    }
    .voucherCode {
      display: flex;
      justify-content: space-between;
      span {
        &:first-child {
          color: $dark-grey;
        }
        &:last-child {
          color: $primary-color;
        }
      }
    }
  }
  .postPaidInfo {
    .entity {
      display: flex;
      justify-content: space-between;
      span {
        margin-bottom: 5px;
        &:first-child {
          color: rgba(69, 73, 79, 1);
        }
      }
    }
  }
  .tablePaperPrint {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: $box-shadow;
    background-color: white;
    .tablePaperPrintHeader {
      padding: 20px;
      box-shadow: $box-shadow;
    }
    .tablePaperPrintBody {
      padding: 20px;
      display: flex;
      flex-direction: column;
      .entity {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
