@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.forgot-password {
  min-width: 300px;
  width: 32%;
  max-width: 600px;
  padding: max(2%, 20px);
  box-shadow: $box-shadow;
  border-radius: 10px;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: linear-gradient(270deg, #EDF9FF 100%, rgba(237, 249, 255, 0) 100%);

  @include md {
    width: calc(100% - 80px);
  }

  @include sm {
    width: calc(100% - 80px);
  }
  h2 {
    text-transform: capitalize;
    @include font-size45();
    white-space: nowrap;
    color: $dark-primary;
    text-align: center;
    font-weight: bold;
  }
}
