@use "sass:math";
@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.curvedInput {
  input .MuiInputAdornment-root {
    border-radius: 50px;
  }

  .MuiInputBase-input:focus {
    border-width: 1px;
    background-color: transparent !important;
  }

  .MuiInput-root {
    &::after {
      border-width: 1px !important;
    }

    &::before {
      border-width: 1px !important;
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 50px;
    border-color: '#007bff';
  }


  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    //transition: background-color 5000s ease-in-out 0s;
    -webkit-background-clip: text;
  }
}