.loginBtn {
  background-color: $primary-color;
  color: white;
  border-color: $primary-color;
  border-radius: 15px;
}

.placeorder {
  background-color: $primary-color;
  color: white;
  border-color: $primary-color;
  border-radius: 20px;
  width: 250px;
  height: 40px;
  font-size: 20px;
  line-height: 25px;
}
