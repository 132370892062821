@import "../../../theme/color.scss";
@import "../../../theme/variables.scss";

.verification-step {
  width: 75%;
  max-width: 500px;
  background-color: #edf9ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5% auto;

  .verification-method {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-evenly;

    .verfication-options {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      margin-top: 2%;

      .phone-input {
        width: 100%;
        margin: auto;
        margin-bottom: 10px;
      }
    }
  }

  @media screen and (min-width: 1800px) {
    margin: 8% auto;
  }

  @media screen and (max-width: 800px) {
    width: 90%;
    max-width: 90%;
  }

  h2 {
    @include font-size24();
    margin-bottom: 10px;
    margin-top: 10px;
  }

  h3 {
    @include font-size18();
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .verify-by {
    font-size: 12px;
    font-weight: bold;
    margin: 10px 0px;
  }

  .verification-options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 2px 0;
    border: 1px solid #ccc;
    height: 40px;
    align-items: center;

    @media screen and (max-width: 1000px) {
      height: unset;
      min-height: 50px;
      padding: 0px 10px;
    }

    @media screen and (max-width: 600px) {
      height: unset;
      min-height: 50px;
      padding: 0px 10px;
      justify-content: flex-start;
    }
  }

  .RadioGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 75%;
  }

  .width100{
    width: 100% !important;
  }

  .form-modalotp {
    min-width: 250px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .otpContainer {
      justify-content: space-between;
      margin-bottom: 10px;

      .otpInput {
        width: 45px !important;
        height: 45px;
        border-radius: 2%;
        background-color: transparent;
      }

      .otpFocus {
        border: 1px solid $primary-color;
      }
    }
  }

  .otp-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    @media screen and (min-width: 1800px) {
      gap: 25px;
    }

    @media screen and (max-width: 800px) {
      gap: 10px;
    }

    .otp-title {
      @include font-size18();
      font-weight: 500;
      color: #000000;
      text-align: center;
    }

    .otp-sub-title {
      font-weight: 500;
      @include font-size15();
      color: #000000;
      text-align: center;
    }

    .mobile-number {
      margin: 0px 10px;
      font-weight: 500;
    }

    .otp-image {
      width: 100px;
      @media screen and (min-width: 1800px) {
        width: 150px;
      }
    }

    .otp-text-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .otp-text {
        @include font-size15();
        color: #81888c;
        cursor: pointer;
        font-weight: 500;
      }
    }

    .text-disabled {
      color: #81888c !important;
    }
  }

  .rotate-image {
    transform-origin: center;
    animation: rotateAnimation 3s linear infinite;
    opacity: 0.8;
  }

  @keyframes rotateAnimation {
    0% {
      transform: rotate(0deg) scale(1);
      /* Start from 0 degrees and original scale */
      opacity: 0.4;
      /* Start with reduced opacity */
    }

    50% {
      transform: rotate(180deg) scale(1.2);
      /* Rotate halfway and scale up */
      opacity: 0.8;
      /* Increase opacity */
    }

    100% {
      transform: rotate(360deg) scale(1);
      /* Rotate a full circle and return to original scale */
      opacity: 1;
      /* Reduce opacity again */
    }
  }

  .iconImage {
    width: 25px;

    @media screen and (max-width: 800px) {
      width: 20px;
    }
  }

  .mlarab-10 {
    margin-left: 10px;
  }

  .errorMsg {
    color: red;
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
}

.radioInfoIcon{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 1;
}