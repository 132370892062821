@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.subCategoryFlow {
  width: 100%;
  display: grid;
  gap: max(25px, 2vmax);
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

  .box {
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
    justify-content: flex-end;
    gap: 10%;
    box-shadow: $box-shadow;
    border: 1px solid white;
    border-radius: 10px;
    transform-origin: unset;
    transform: unset;
    padding: 5% 0 2%;
    text-transform: capitalize;
    font-weight: 400;
    color: $primary-color-opacity;
    position: relative;
    background-color: white;
    min-height: 240px;

  }

  .box:hover {
    background: #DBEEFF !important;
  }

  img {
    object-fit: contain;
    max-width: 250px;
    max-height: 180px;
  }

  p {
    color: black;
    @include font-size20();
    text-align: center;
  }

  .conditionsTooltip {

    .MuiTooltip-tooltip {
      max-width: 300px;

    }
  }
}

.nodataComponent {
  margin: auto;
}

.boxdisabled {
  img {
    opacity: 1;
    filter: grayscale(50%);
  }

  p {
    opacity: 1;
    filter: grayscale(50%);
  }
}


.comingSoon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  @include font-size16;
  text-align: center;
  height: fit-content;
  margin: auto !important;
  font-weight: 600;
  background: linear-gradient(314.8deg, #4ba4f0 0%, #71e4ac 100%);
  z-index: 5;
  color: white;
  -webkit-text-fill-color: white;
  opacity: 1 !important;
  filter: grayscale(0) !important;
}