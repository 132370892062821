@import "/src/theme/variables.scss";
@import "/src/theme/color.scss";

.personal_info {
  .codeCountry {
    width: 25%;
    min-width: 100px;
  }

  .rows {
    display: flex;
    gap: 5%;

    @media only screen and (max-width: 601px) {
      flex-direction: column;
    }

    .inputControl {
      width: 100%;
    }
  }

  .phoneInput1 {
    width: 47.5%;

    @media only screen and (max-width: 601px) {
      width: 100%;
    }

    .search-select {
      width: 25%;
    }
  }


  .enable-notification {
    @include font-size();
    color: $black;
    margin-top: 45px;
    padding-bottom: 36px;
  }





  .MuiFormControl-root {
    @include font-size22();
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;

    .buttonPersonal {
      position: absolute;
      bottom: 5%;
      right: 0;
    }
  }

  .code {
    width: 40%;
  }
}

.direction-left {
  direction: ltr !important;
}