@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.formContainer {
  width: 100%;
  margin: 0% auto;
  min-width: 300px;
  height: fit-content;
  box-shadow: $box-shadow;
  border-radius: 10px;
  padding: calc(10px + 1%);
  background-color: white;

  label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      font-weight: 500;
    }
  }

  .borde {
    padding: 10px;
  }

  h1 {
    text-align: center;
  }

  .inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 5%;

    .inputForm,
    .countryForm {
      display: flex;
      flex-direction: column;
      width: 45%;
      margin: 10px 0 !important;
    }
  }

  .consumerDetails {
    display: flex;
    flex-direction: column;
    max-height: 60vh;

    &::-webkit-scrollbar {
      width: 5px;
    }
  }

  .placeorder {
    margin: 4% 1%;
    float: right;
  }
}

.MuiAutocomplete-inputRoot {
  padding-right: 0 !important;
}

.modalotp {
  min-width: 375px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .otpContainer {
    justify-content: space-between;
    margin-bottom: 20px;

    .otpInput {
      width: 45px !important;
      border: none;
      outline: none;
      border-bottom: 1px solid $black;
    }

    .otpFocus {
      border: none;
      border-bottom: 1px solid $primary-color;
    }
  }
}

.dialogActions.MuiDialogActions-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 24px;

  .sendOTP {
    width: fit-content;
    gap: 2px;
    text-decoration: underline;

    b {
      text-transform: lowercase;
      font-size: 10px;
    }
  }
}

.dialogTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0px;
}

.selectdialogDisclaimer {
  border-radius: 10px;
}

.dialogAction.MuiDialogActions-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px 24px;
}

.mt {
  margin: 1% 0%;
}

.form-inputs-triple-children {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px 40px;
  align-items: center;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @include sm {
    grid-template-columns: 1fr;
  }
}

.form-inputs-double-children {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 40px;
  align-items: center;

  @include sm {
    grid-template-columns: 1fr;
  }

  .radio-wrapper {
    margin: 15px 0px 5px 0px;
  }
}

.last-row-demand {
  margin: 20px 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  @media screen and (max-width: 900px) {
    flex-flow: column nowrap;
    justify-content: unset;
  }
}

.errorMsg {
  color: red;
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.css-wsw4xt-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  height: 43px !important;
}

.flagBUtton {
  background-color: transparent;
  border: none;
  border: 1px solid transparent;
  border-radius: 3px;
}

.formContainerArabic label {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.ar {
  background-color: #165d31;
  color: white;
}

.rowAr {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.iconImage {
  width: 30px;
}

.mlarab-10 {
  margin-left: 8px
}

.mR-10 {
  margin-right: 10px
}