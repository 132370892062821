@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.paginator-section {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: unset;
  justify-content: end;
  background-color: white;
  margin: 10px 0px;
  height: 50px;

  // @media only screen and (min-width: 1800px) {
  //   position: fixed;
  //   width: 85%;
  // }
}

.justify-content-start-pag {
  justify-content: start !important;
}

.paginator {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 10px;
  border: 0.5px solid $primary-color;
  border-radius: 30px;
  min-width: 40px;
  padding: 4px;
  background-color: #fff;

  @media only screen and (min-width: 1800px) {
    margin-top: 20px;
  }
}