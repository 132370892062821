@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.container {
  @media only screen and (min-width: 1800px) {
    max-width: 1500px;
  }

  .slide-content {
    display: flex;
    align-items: center;
    margin-top: 70px;
    position: relative;

    @media only screen and (max-width: 1180px) {
      flex-direction: column-reverse !important;
      text-align: center;
    }
  }

  .slideNumber {
    color: #ffffff;
  }

  .text {
    flex: 1;
    padding: 0 20px;
    //margin-top: 20px;
    color: #ffffff;
    margin-left: 10px;
    margin-right: 10px;

    .description {
      @include font-size18();
    }
  }

  .image {
    flex: 1;
    margin-top: 20px;
    display: flex;
    justify-content: end;
    text-align: center !important;
  }

  .image img {
    width: 324px;
    height: 340px;
    margin-left: 10px;
    margin-right: 10px;

    @media only screen and (min-width: 1800px) {
      width: 400px;
      height: 400px;
    }
  }

  .minwidth150 {
    min-width: 100px !important;
    margin-left: 1px !important;
    margin-right: 5px !important;
    background: linear-gradient(90deg, #4ba4f0 0%, #55b4df 29%, #71e4ac 100%) !important;
    color: white;
    @include font-size18();

    &:hover{
      opacity: 0.9;
      background: #4ba4f0 !important;

    }

    @media only screen and (min-width: 1800px) {
      min-width: 100px !important;
    }

    @media only screen and (max-width: 767px) {
      min-width: 150px !important;
    }
  }

  .slick-dots {
    bottom: 400px;

    @media only screen and (min-width: 1800px) {
      bottom: 450px;
    }

    @media only screen and (max-width: 1180px) {
      display: none !important; // Hide dots on small screens
    }

    @media only screen and (max-width: 767px) {
      display: none !important; // Hide dots on small screens
    }
  }

  .slick-dots li {
    margin: 0 5px;
  }

  .slick-dots li button {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: transparent !important;
    cursor: pointer;
    outline: none !important;
    box-shadow: none !important;
    border: 3px solid #4ba4f0 !important; // Remove border
  }

  .slick-dots li button:before {
    color: transparent !important;
  }

  .slick-dots li.slick-active button {
    border: none !important;
    background: linear-gradient(180deg, #4ba4f0 0%, #55b4df 29%, #71e4ac 100%);
  }

  .slick-prev {
    width: 60px !important;
    height: 60px !important;
    left: -50px;

    @media only screen and (max-width: 767px) {
      width: 60px !important;
      height: 60px !important;
      left: 5px !important;
    }
  }

  .slick-next {
    width: 60px !important;
    height: 60px !important;
    right: -50px;

    @media only screen and (max-width: 767px) {
      width: 60px !important;
      height: 60px !important;
      right: 5px !important;
    }
  }

  .buttons-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
    margin: 40px 0px;

    @media only screen and (max-width: 1180px) {
      justify-content: center;
      margin: 10px 0px;
    }

    @media only screen and (max-width: 767px) {
      justify-content: center;
      margin: 10px 0px;
    }
  }
}

.bgwall {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: clamp(10px, 2vh, 25px) clamp(15px, 2vw, 50px);
  min-width: fit-content;
  width: 100%;

  @media only screen and (max-width: 1200px) {
    height: unset !important;
  }
}

.bgImage {
  background-color: #dcf1ff70;
}



.loadingContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  @media only screen and (max-width: 800px) {
    top: 55%;
  }

  .spinner {
    width: 100px;
    /* Adjust width of spinner image as needed */
    height: auto;
    /* Maintain aspect ratio */
  }

  /* Define the keyframes for the spin animation */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* Apply the spin animation to an element */
  .spin {
    /* Use the defined keyframes */
    animation: spin 2s linear infinite;
    /* Adjust duration and timing function as needed */
  }

}

.overLayContainer {
  background-color: #00000073;
  border-radius: 10px;
  color: #fff !important;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin-top: 5vh;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  padding: clamp(10px, 2vh, 25px) clamp(15px, 2vw, 50px);
  width: 80%;

  @media only screen and (min-width: 1800px) {
   height: 70vh;
  }

  @media only screen and (max-width: 600px) {
    //height: unset !important;
    padding: unset!important;
    width: 90% !important;
  }

}