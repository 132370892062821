.alert-root {
  cursor: pointer;
  position: fixed;
  top: 75px;
  transition: 1s all ease;
  z-index: 1000;

  .disapear {
    transform: translate(50%, 50%);
  }
}
