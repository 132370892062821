@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.chooseMethod {
  width: 100%;
  display: grid;
  gap: max(25px, 2vmax);
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
    height: 250px;
    justify-content: flex-end;
    gap: 10%;
    box-shadow: $box-shadow;
    border: 1px solid white;
    border-radius: 20px;
    transform-origin: unset; //skeleton
    transform: unset; //skeleton
    padding: 5% 0 2%;
    text-transform: capitalize;
    font-weight: 400;
    color: $primary-color-opacity;
    position: relative;
    background-color: white;

    &:hover {
      box-shadow: 0px 0px 5px 1px $primary-color-opacity;
    }
    img {
      object-fit: contain;
      max-width: 70%;
      max-height: 50%;
    }
    p {
      color: black;
      @include font-size20();
      text-align: center;
    }
  }
}
