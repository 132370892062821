@import "../../theme/color.scss";
@import "../../theme/variables.scss";

.sideBox-component {
  background-color: #f8faff;
  min-height: 75vh;
  width: 100%;
  min-width: 250px;
  max-width: 400px;
  box-shadow: $box-shadow;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  padding: 10% 0;

  &:hover {
    box-shadow: 0px 0px 5px 1px $primary-color-opacity;
  }

  @media only screen and (max-width: 800px) {
    width: 100%;
    margin: 0 auto;
    max-width: 800px;
    height: 65vh;
  }

  .sideBox-title {
    font-weight: 700;
    @include font-size25();
    text-align: center;
    display: flex;
    justify-content: start;
    align-items: start !important;
    color: $new_primary_dark_blue;
  }

  .sideBox-img {
    max-height: 40%;
    max-width: 90%;

    @media only screen and (min-width: 1800px) {
      max-height: 50%;
    }
  }

  .sidebox-Condition {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .condition-val {
      font-weight: 400;
      font-size: 20px;
      color: $new_primary_dark_blue;
    }

    .medalsvg {
      width: 40px;
      height: 40px;
    }

    .RecyclableSVG {
      width: 40px;
      height: 40px;
      margin-bottom: 10px;
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1%;
    margin: 0 auto;
    padding: 1% 0 0;
    height: auto;
    justify-content: space-evenly;
    font-weight: 500;

    .Row {
      width: 75%;
      display: flex;
      align-items: center;
      justify-content: start;
      @include font-size14();
      margin: 1px auto;

      .key {
        display: flex;
        gap: 10px;
        width: 65%;
        @include font-size16();
        //align-items: center;
        text-transform: capitalize;
      }

      .width40 {
        width: 45%;
      }

      .Value {
        // white-space: nowrap;
        color: $primary_color;
        width: 40%;
        @include font-size16();
        text-align: start;
        margin: 5px 0px;
        text-transform: capitalize;
        cursor: pointer;
      }
    }
  }
}