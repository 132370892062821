@import "../../../theme/color.scss";
@import "../../../theme/variables.scss";

.filformContainer {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  margin: 4% auto;

  @media only screen and (min-width: 1800px) {
    margin: 2% auto;
  }

  .width70 {
    width: 100%;
    margin: auto;

    @media only screen and (min-width: 1400px) {
      width: 65%;
    }
  }

  .button-form {
    display: flex;
    justify-content: end !important;
    width: 100%;

    @media only screen and (max-width: 800px) {
      justify-content: center;
      width: 100%;
    }
  }

  .errorMsg {
    color: red;
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
}



.MuiOutlinedInput-root {
  border-color: black !important; // Default border color
  transition: border-color 0.3s ease; // Smooth transition on border color change

  fieldset: {
    border-color: black; // Default border color
    transition: border-color 0.3s ease; // Smooth transition on border color change
  }

  &:hover fieldset {
    border-color: black !important;
  }

  &.Mui-focused fieldset {
    border-color: black !important;
  }

  /* Border color when input is not focused and empty */
  &:not(:focus) {
    fieldset {
      border-image: linear-gradient(to right, #4ba4f0 0%,  #55b4df 30.5%,  #71e4ac 100%, #71e4ac 100%,);
      border-image-slice: 1;
    }
  }
}

.css-1gsmjx1-MuiFormHelperText-root {
  color: red !important;
}