@import "/src/theme/variables.scss";
@import "/src/theme/color.scss";

.account_details {
  @media screen and (max-width: 601px) {
    display: none;
  }

  .account_details_container {
    border-radius: 10px;
    display: flex;
    gap: clamp(20px, 10%, 200px);
  }

  .tabs {
    background: $white;
    width: 40% !important;
    max-width: 300px;
    height: fit-content;
    box-shadow: $box-shadow;
    border-radius: 10px;

    .Mui-selected {
      background-color: $primary-color-opacity;
    }

    .MuiTabs-indicator {
      left: 0;
    }

    button {
      text-transform: none !important;
      font-weight: unset !important;
      max-width: unset;
      align-items: flex-start;
    }
  }

  .outlet {
    position: relative;
    background: $white;
    width: 100%;
    box-shadow: $box-shadow;
    padding: 15px 20px;

    @media only screen and (max-width: 601px) {
      box-shadow: none;
      padding: 0;
      margin: 0;
    }

    hr {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}

.Responsive {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (min-width: 601px) {
    display: none;
  }
}

.accordion {
  border: none !important;
}

.Accordion_account {
  display: flow-root !important;
}

.css-1q60rmi-MuiAutocomplete-endAdornment {
  position: static !important;
}