@import "../../../../theme/color.scss";
@import "../../../../theme/variables.scss";

.Device_box {
  background-color: #f8faff;
  width: 100%;
  min-width: 250px;
  max-width: 400px;
  box-shadow: $box-shadow;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  &:hover {
    box-shadow: 0px 0px 5px 1px $primary-color-opacity;
  }

  @media only screen and (max-width: 800px) {
    width: 100%;
    margin: 0 auto;
    max-width: 800px;
  }

  .img {
    display: flex;
    max-width: 270px;
    max-height: 300px;

    @media only screen and (min-width: 1800px) {
      max-width: 100%;
      max-height: 100%;
    }

    @media only screen and (max-width: 800px) {
      max-width: 50%;
      max-height: 35%;
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1%;
    margin: 0 auto;
    border-top: 1px solid #9a9a9a;
    height: 50%;
    justify-content: space-evenly;

    @media only screen and (max-width: 800px) {
      height: 250px
    }


    .Row {
      margin: min(1%, 20px) auto 0;
      width: 90%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @include font-size17();
      font-weight: 600;

      @media only screen and (min-width: 1800px) {
        width: 93%;
      }

      .Condition {
        gap: 5px;
        align-items: center;
        color: $new_primary_blue;
      }
    }

    .Row-1 {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include font-size15();
      font-weight: 500;
      margin: 1px auto;

      @media only screen and (min-width: 1800px) {
        width: 93%;
      }

      .Condition {
        display: flex;
        gap: 10px;
        align-items: center;

        .RecyclableSVG {
          width: 20px;
          height: unset;

          @media only screen and (min-width: 1800px) {
            width: 30px;
          }
        }

        p {
          margin: 0%;
          white-space: nowrap;
          color: $new_primary_blue;
        }
      }

      .Estimated {
        white-space: nowrap;
        color: $primary_color;
        direction: ltr;
        text-align: end;
      }

      .starSVG {
        width: 20px;
        height: unset;

        @media only screen and (min-width: 1800px) {
          width: 30px;
        }
      }
    }
  }
}

.conditionsTooltip {
  .MuiTooltip-tooltip {
    max-width: 300px;
  }
}

.Estimated-title {
  color: $new_primary_blue !important;
}

.upto {
  color: #555f6a;
}