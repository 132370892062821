@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.form-input-wrapper {
  margin: 0px 0px 10px 0px;

  .react-datepicker-popper button {
    color: white !important;
    margin-top: 4px;
  }

  .react-datepicker__header {
    background-color: $primary-color !important;
  }

  .react-datepicker__day {
    color: $primary-color !important;
  }

  .react-datepicker__day-names {
    background-color: white !important;
  }

  .react-datepicker__current-month {
    color: white !important;
    margin-bottom: 5px;
  }

  .react-datepicker__day--selected .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: #d6f3f4 !important;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #d6f3f4 !important;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__year-read-view--selected-year,
  .react-datepicker__navigation-icon::before {
    border-color: white !important;
  }
}

.required-start {
  color: "red";
}

.form-upload-children {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  .ratio-label {
    color: $grey;
  }
}

.dropzone {
  background-color: white;

  img {
    object-fit: contain;
    width: 90%;
    height: 200px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.MuiTooltip-tooltip {
  &.checkbox-moreinfo {
    background-color: white;
    color: $primary-color;
  }
}

.react-daterangeWrapper {
  position: relative;

  &.fullWidth {
    width: 100%;
  }
}

.custom-end-adornment-class {
  /* Your custom styles for the end adornment (arrow icon) */
  right: 190px !important;
}
