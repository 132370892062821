@import "../../../theme/color.scss";
@import "../../../theme/variables.scss";

.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //height: 50vh;
  margin: 10% auto;

  .Successfullyplacedtext {
    color: #6fe0b0;
    font-size: 24px;
    margin-top: 10px;
  }

  .otp-image {
    width: 150px;
  }
  .successImage{
    max-width: 100%;
    max-height: 100%;
  }

  .opacity-image {
    transform-origin: center;
    animation: opacityAnimation 3s linear infinite;
    opacity: 0.8;
  }

  @keyframes opacityAnimation {
    0% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.5;
    }

    70% {
      opacity: 0.8;
    }

    100% {
      opacity: 1;
    }
  }
}
