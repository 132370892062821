@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.phoneInput {
  display: flex;
  flex-direction: row-reverse;
  gap: 0px;

  .form-control.inputClass {
    width: 100%;
    border-radius: 4px;
    padding: 8.5px 14px;
    background-color: transparent;
    height: 40px;
    border: 1px solid;
    border-image: linear-gradient(to right, #4ba4f0 0%, #55b4df 30.5%, #71e4ac 100%, #71e4ac 100%, );
    border-image-slice: 1;
  }

  .borderInput {
    border-radius: 5px;
    border: 1px solid;
    border-image: linear-gradient(to right, #4ba4f0 0%, #55b4df 30.5%, #71e4ac 100%, #71e4ac 100%, );
    border-image-slice: 1;
  }

  .arabic {
    display: flex !important;
    flex-direction: row !important;
    justify-content: end !important;
  }


  .buttonClass.flag-dropdown {
    position: relative;
    border: 1px solid;
    border-image: linear-gradient(to right, #4ba4f0 0%, #55b4df 30.5%, #71e4ac 100%, #71e4ac 100%, );
    border-image-slice: 1;
    height: 40px;
    border-radius: 4px;

    .selected-flag {
      display: flex;
      width: 80px;
      align-items: center;
      height: 40px;

      .arrow {
        position: relative;
        top: 46%;
        left: 47px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #555;
      }
    }
  }

  .special-label {
    display: none !important;
  }
}

.phone-input-mg-form {
  margin: 0px 0px 15px 0px;

  label {
    color: black;
    @include font-size14();
    font-weight: 400;
  }
}

.label-Phone {
  font-size: 12px !important;
  font-weight: bold !important;
  color: black !important;
  margin-top: 10px !important;
}