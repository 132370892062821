@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.LoginPage {
  width: 846px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 460px;
  box-shadow: $box-shadow;
  border-radius: 29px;
  padding: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: linear-gradient(270deg, #EDF9FF 100%, rgba(237, 249, 255, 0) 100%);

  @keyframes login {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    max-width: 800px;
  }

  @include sm {
    width: fit-content;
  }

  .wrap1 {
    display: flex;
    flex-direction: column;
    width: 70%;

    @include sm {
      width: 100%;
    }

    .mobilelogo {
      display: flex;
      justify-content: center;
      padding: 20px 0px;

      @media screen and (min-width: 700px) {
        display: none;
      }
    }

    .loginForm {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .forgotPassword {
        width: fit-content;

        color: rgba(154, 154, 154, 1);
        text-decoration: none;
        margin-bottom: 2%;
        float: right;

        &:hover {
          color: $primary-color;
        }
      }
    }

    .rememberMe {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin: 0px 10px;

      @media screen and (max-width: 700px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .wrap2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    padding: 3px 0px 3px 40px;

    .logo {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    @include sm {
      display: none;
    }
  }

  .wrapArabic {
    padding: 3px 40px 3px 0px !important
  }
}

.arabic-input .MuiFormHelperText-root {
  text-align: right;
  margin: 5px 10px;
}

/* css file */
.disabled-link {
  pointer-events: none;
}

.curvedInput {
  .MuiInputAdornment-root {

    border-radius: 50px !important;
  }

  .MuiOutlinedInput-root {

    /* Border color when input is not focused and empty */
    &:not(:focus) {
      fieldset {
        border: 1px solid #55b4df;
        border-radius: 50px !important;
      }
    }

    &:hover fieldset {
      border-color: #4ba4f0 !important;
    }
  }

  .MuiInputBase-input:focus {
    border-width: 1px !important;
    border-radius: 50px !important;
    background-color: transparent !important;
  }

  .MuiInput-root {
    &::after {
      border-width: 1px !important;
      border-radius: 50px !important;
    }

    &::before {
      border-width: 1px !important;
      border-radius: 50px !important;
    }
  }

  input {
    padding: 10px 14px;
  }

  .MuiOutlinedInput-root {
    border-radius: 50px !important;
    border: 1px solid transparent !important;

  }
}