@import "/src/theme/variables.scss";
@import "/src/theme/color.scss";
@import "/src/theme/form.scss";
@import "/src/theme/button.scss";

body {
  font-family: "Roboto";
  font-style: normal;
  overflow-y: overlay;

  a {
    text-decoration: none;
    color: white;

    &:hover {
      color: unset;
    }
  }

  overflow-x: hidden;
}

.custom-swal-container {
  z-index: 9999;
  /* Set a high z-index value */
}

.custom-swal-background {
  background-color: rgb(27, 27, 66);
  /* Set the background color to blue */
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(75, 164, 240, 0.3);
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4ba4f0;
}

//input type = number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

label {
  text-transform: capitalize;
}

html {
  font-size: 16px;
}

h1 {
  @media only screen and (max-width: 1600px) {
    font-size: 2.2rem;
  }

  margin: 10px 0px 20px 0px;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.3rem;
}

.picea_layout_TpPdL .picea_modal_T4Etr {
  margin: 100px 0px !important;
}

.css-1vhpe4y>* {
  width: 100px;
}

.css-q1l49x {
  background-color: #071d40 !important;
}

.css-a3tljx {
  padding: 10px 76px !important;
}

.text-center {
  text-align: start;
}

.text-right {
  text-align: right;
}

// To switch to arabic langauge
.arabic-component {
  direction: rtl;
  // font-family: "Arial", sans-serif;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: right;
}

.direction-left {
  direction: ltr !important;
}

.direction-right {
  direction: rtl;
}

.pad-10 {
  padding: 10px !important;
}

.padx-5 {
  padding: 0 5% !important;
}

.h85 {
  height: 85vh;

  @media only screen and (max-width: 1200px) {
    height: unset;
  }
}

.h80 {
  height: 80vh;

  @media only screen and (max-width: 1200px) {
    height: unset;
  }
}

.stepperIcon {
  width: 45px;
  height: 45px;
  margin-bottom: 5%;

  @media only screen and (min-width: 1800px) {
    width: 50px;
    height: 50px;
    margin-bottom: 10%;

  }

  @media only screen and (max-width: 1400px) {
    width: 35px;
    height: 35px;
  }

  @media only screen and (max-width: 1200px) {
    width: 30px;
    height: 30px;
  }

  @media only screen and (max-width: 1000px) {
    width: 25px;
    height: 25px;
  }

  @media only screen and (max-width: 800px) {
    width: 25px;
    height: 25px;
  }
}
