@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.sellmyiphone {
  width: 100%;
  display: grid;
  gap: max(25px, 2vmax);
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
    justify-content: flex-end;
    gap: 10%;
    box-shadow: $box-shadow;
    border: 1px solid white;
    border-radius: 10px;
    transform-origin: unset;
    transform: unset;
    padding: 5% 0 2%;
    text-transform: capitalize;
    font-weight: 400;
    color: $primary-color-opacity;
    position: relative;
    background-color: white;
    transition: background-color 0.3s ease;
    min-height: 240px;
  }

  .box:hover {
    background: #dbeeff !important;
  }

  p {
    color: black;
    margin: 0;
    @include font-size20();
    text-align: center;
    text-transform: none;
    height: fit-content;
    text-overflow: ellipsis;
    width: 90%;
  }

  .productName {
    color: #234292;
    @include font-size18();
  }

  .description {
    color: #7989a3;
    @include font-size14();
  }

  .links {
    display: flex;
    flex-direction: row;
    color: $primary-color;
    text-transform: none;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    @include font-size18();

    span {
      font-weight: 500;
      color: $primary-color;
      color: #555f6a;
      margin: 0px 5px;
    }
  }

  img {
    object-fit: cover;
    max-width: 250px;
    max-height: 180px;
  }
}

.No-Data-Found {
  width: fit-content !important;
  height: fit-content !important;
  margin: auto !important;
  color: lightgray;
  font-size: 30px;
}

.conditionsTooltip {
  .MuiTooltip-tooltip {
    max-width: 300px;
  }
}

.min-320 {
  min-height: 320px !important;
}
