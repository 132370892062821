@import "../../theme/color.scss";
@import "../../theme/variables.scss";

.main-customerForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 75vh;
  gap: 10px;
  height: auto;
  padding-bottom: 1%;

  @media only screen and (min-width: 1800px) {
    min-height: 75vh;
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: start;
    height: unset;
    margin-bottom: 10px;
  }

  .step-button {
    @include font-size16();
    display: flex;
    flex-direction: column !important;
  }

  .step-button .MuiStepLabel-root {
    display: flex;
    flex-direction: column !important;
  }

  .side-deviceInfo {
    display: flex;
    flex: 1;
    min-width: 0;
  }

  .side-form {
    display: flex;
    flex-direction: column;
    background-color: $new_light_blue;
    border-radius: 10px;
    border: 1px solid $new_light_blue;
    flex: 3;
    min-width: 0;
    justify-content: start;
    padding: 1%;

    @media only screen and (max-width: 1500px) {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  @media only screen and (max-width: 1300px) {
    .side-form {
      flex: 1.5;
    }
  }

  @media only screen and (max-width: 800px) {

    .side-deviceInfo,
    .side-form {
      flex: none;
      width: 100%;
    }
  }

  .step-button .MuiStepLabel-label.Mui-completed {
    @include font-size18();
  }
}

.main-formTitle {
  font-weight: 500;
  @include font-size40();
  float: inline-end;
  width: 75%;
  color: #ffffff;
}

.main-print-icon {
  font-size: 70px !important;
  color: #71e4ac !important;
}

.printModel {
  border: 1px solid;
  border-radius: 3% !important;
}

.dialog-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0px;
  color: $new_primary_dark_blue;

  @media only screen and (max-width: 400px) {
    font-size: 15px !important;
    text-align: center;
    margin: 5px 0px;

  }

}

.dialog-action.MuiDialogActions-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3% 4%;

  .buttonsMsg {
    display: flex;
    justify-content: center;
    flex-direction: row;
    column-gap: 10px;
  }
}

.bg {
  background-color: linear-gradient(90deg,
      #4ba4f0 0%,
      #55b4df 30.5%,
      #71e4ac 100%,
      #71e4ac 100%) !important;
}

.responsivnessbtn {
  @media only screen and (max-width: 400px) {
    width: 50% !important;
  }
}