@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.selectBrand {
  width: 100%;
  display: grid;
  gap: max(25px, 2vmax);
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
    justify-content: flex-end;
    gap: 10%;
    box-shadow: $box-shadow;
    border: 1px solid white;
    border-radius: 10px;
    padding: 5% 0 2%;
    text-transform: capitalize;
    font-weight: 400;
    color: $primary-color-opacity;
    position: relative;
    background-color: white;
    min-height: 240px;
  }

  .box:hover {
    background: #dbeeff !important;
  }

  img {
    object-fit: cover;
    max-width: 250px;
    max-height: 180px;
  }

  p {
    color: black;
    @include font-size20();
    text-align: center;
  }

  .conditionsTooltip {
    .MuiTooltip-tooltip {
      max-width: 300px;
    }
  }
}

.box-Skeleton {
  background-color: white !important;
  border: 1px solid white !important;
  border-radius: 10px !important;

}
