@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.Order_detailsDisclaimer {
  width: 100%;
  margin: 0 auto;

  // .barcode {
  //   font-family: "Libre Barcode EAN13 Text", cursive;
  //   font-size: 100px !important;
  //   float: right;
  // }
  h4 {
    white-space: nowrap;
    font-weight: 500;
    color: $primary_color;
  }

  .Items_summary {
    border: 1px solid #eaf1fc;
    border-radius: 10px;

    @media print {
      margin: 0 0;
    }
  }

  .Details_row {
    margin-top: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .Summary {
      margin-bottom: 3%;
      width: 45%;
      padding: 5px;

      p {
        margin: 0%;
        text-align: end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include font-size16();
      }

      .Info {
        border-radius: 10px;
        padding: 1% 5%;
        border: 1px solid #eaf1fc;

        .Sub_row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1%;
          min-height: 20px;
          font-size: 17px;

          .Left {
            font-weight: 500;
          }
        }

        .buttonContainer {
          display: flex;
          gap: 5%;
        }
      }
    }
  }
}

.tableDisclaimer {
  width: 100%;

  thead {
    width: 100%;
    border-bottom: 2px solid #eaf1fc;
  }

  tbody,
  td,
  tfoot,
  th,
  thead {
    padding: 5px 20px;
  }

  thead {
    .h1 {
      padding: 5px 20px;
      text-align: left !important;
    }

    .h6 {
      padding: 5px 20px;
      text-align: left !important;
      color: $darkest-grey;
    }

    .h6-right {
      text-align: right !important;
    }

    .h1-arabic {
      font-size: 30px !important;
    }
  }

  tr {

    td,
    th {
      &:last-child {
        text-align: end;
      }

      text-transform: capitalize;
    }

    th {
      color: $primary-color;
      white-space: nowrap;
    }
  }
}

.min20 {
  min-height: 20px !important
}

.pdleft5{
  padding-left: 5% !important
}

.pdleft6{
  padding-left: 6% !important
}
.pagebreakbefor{
  page-break-before: always !important
}